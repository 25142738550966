import "./main.css";

interface PreloaderProps {
    handleEnterClick: () => void;
}

export const Preloader: React.FC<PreloaderProps> = ({ handleEnterClick }) => {
    return (
        <div className="loading_container">
            <div className="min-h-screen bg-primary font-istok ">
                <div className="whole p-4">
                    <div className="explain text-center mb-8 text-lg">
                        It's necessary to make it worse to better understand how
                        it should be done the right way.
                    </div>
                    <div className="total flex flex-col md:flex-row gap-8">
                        <div className="left">
                            <div className="title text-6xl font-bold mb-6">
                                HELLO
                            </div>
                            <div className="flex flex-col content gap-y-10">
                                <p className="first_content text-base md:text-lg leading-relaxed">
                                    Sit back, relax, and don't rush to close
                                    your browser if you're curious to know how
                                    not to.
                                </p>
                                <p className="text-base md:text-lg leading-relaxed">
                                    You're about to experience a site filled
                                    with terrible design, chaotic layout,
                                    confusing structure, and unbalanced colours.
                                    The only thing truly done right here is the
                                    content-we made sure of that. Everything
                                    else? It's deliberately unfortunate. But
                                    it's crafted with style, passion, and
                                    dedication. While the design is brilliantly
                                    awful, it's also unique-just like you and
                                    me. We did this intentionally because we
                                    know how not to.
                                </p>
                                <p className="third_content text-base md:text-lg leading-relaxed">
                                    Creating something everyone loves is
                                    impossible, but creating something everyone
                                    remembers? That's within reach.
                                </p>
                                <p className="third_content text-base md:text-lg leading-relaxed">
                                    Enjoy, and welcome to Joker.
                                </p>
                            </div>
                        </div>
                        <div className="right flex flex-col items-center justify-end md:gap-y-40 gap-y-20">
                            <button
                                onClick={handleEnterClick}
                                className="button px-12 py-4 bg-black text-white hover:bg-gray-800 transition-colors duration-300 text-xl uppercase"
                            >
                                ENTER
                            </button>
                            <p className="third_content text-base md:text-lg text-white leading-relaxed">
                                JokerwebDesign.com-not just one more design
                                agency
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

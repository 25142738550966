// src/context/LoadingContext.tsx
import React, { createContext, useContext, useState, type ReactNode } from 'react';

// Define the shape of our context
interface LoadingContextType {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  showLoader: () => void;
  hideLoader: () => void;
}

// Create the context with an initial default value
const LoadingContext = createContext<LoadingContextType>({
  isLoading: false,
  setIsLoading: () => null,
  showLoader: () => null,
  hideLoader: () => null
});

// Props type for the provider component
interface LoadingProviderProps {
  children: ReactNode;
}

// Provider component that wraps the app
export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const showLoader = (): void => setIsLoading(true);
  const hideLoader = (): void => setIsLoading(false);
  
  const value = {
    isLoading,
    setIsLoading,
    showLoader,
    hideLoader
  };

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
};

// Custom hook to use the loading context
export const useLoading = (): LoadingContextType => {
  const context = useContext(LoadingContext);
  
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  
  return context;
};
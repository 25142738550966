import Arrow from "../Assets/Img/arrow.svg";
import BeachOne from "../Assets/Img/beach_one.jpg";
import BeachTwo from "../Assets/Img/beach_two.jpg";
import BeachThree from "../Assets/Img/beach_three.jpg";
import Earth from "../Assets/Img/earth.svg";
import Foot from "../Assets/Img/foot.svg";
import Pen from "../Assets/Img/pen.svg";
import Phone from "../Assets/Img/phone.svg";
import Tank from "../Assets/Img/tank.svg";

export const PRIMARY_CARD = [
    {
        id: "1",
        background: "bg-section_maincard",
        title: "Website redesign",
        content:
            "Transform your online presence with our expert website redesign service, tailored to boost engagement and reflect the unique essence of your brand.",
        circleColor: "bg-section_maincard",
        arrow: Arrow,
    },
    {
        id: "2",
        background: "bg-section_maincard",
        title: "Web design and UI",
        content:
            "Enhance your brand with stunning web design and seamless UI for an unforgettable user experience.",
        circleColor: "bg-section_maincard",
        arrow: Arrow,
    },
    {
        id: "3",
        background: "bg-section_maincard",
        title: "UX design",
        content:
            "Elevate your brand with exceptional UX design for a smooth, engaging user journey.",
        circleColor: "bg-section_maincard",
        arrow: Arrow,
    },
    {
        id: "4",
        background: "bg-section_maincard",
        title: "Web development",
        content:
            "Boost your brand with powerful web development for a fast, seamless online experience.",
        circleColor: "bg-section_maincard",
        arrow: Arrow,
    },
    {
        id: "5",
        background: "bg-section_maincard",
        title: "Paid social",
        content:
            "We can help your business drive enormous amount of traffic to your website via paid social platforms like Google and Facebook.",
        circleColor: "bg-section_maincard",
        arrow: Arrow,
    },
    {
        id: "6",
        background: "bg-section_maincard",
        title: "Search Engine Optimization",
        content:
            "Drive targeted traffic to your website with our expert SEO services, designed to enhance rankings and maximize ROI.",
        circleColor: "bg-section_maincard",
        arrow: Arrow,
    },
    {
        id: "7",
        background: "bg-section_maincard",
        title: "Email marketing",
        content:
            "Engage your audience and drive conversions with personalized, results-driven email marketing.",
        circleColor: "bg-section_maincard",
        arrow: Arrow,
    },
    {
        id: "8",
        background: "bg-section_radial",
        title: "Chat GPT and AI services",
        content:
            "Unlock smarter solutions and streamline operations with our cutting-edge AI services tailored to your business needs.",
        circleColor: "",
        arrow: Arrow,
    },
];

export const SECOND_CARD = [
    {
        id: "1",
        title: "Freedom to express",
        content:
            "True freedom online means sharing your unique voice without limits. Embrace creativity - let your ideas stand out.",
    },
    {
        id: "2",
        title: "Unlimited source for creativity",
        content:
            "The internet offers endless tools and inspiration from every corner of the world. It's a boundless space where ideas can grow and evolve freely.",
    },
];

export const THIRD_CARD = [
    {
        id: "1",
        img: BeachOne,
        title: "Creative audit",
        content:
            "Unlock your brand's full potential with our creative audit service, designed to uncover hidden strengths and fresh opportunities.",
    },
    {
        id: "2",
        img: BeachTwo,
        title: "Creative direction",
        content:
            "Elevate your brand's vision with our creative direction service, guiding you from concept to impactful execution.",
    },
    {
        id: "3",
        img: BeachThree,
        title: "Video",
        content:
            "Bring your story to life with our video services, creating compelling visuals that captivate and engage your audience.",
    },
];

export const FORTH_CARD = [
    {
        id: "1",
        style: "bg-gray_600",
        content: "Our Philosophy",
    },
    {
        id: "2",
        style: "bg-gray_500",
        content: "Every creature must live.",
        icon: Foot,
    },
    {
        id: "3",
        style: "bg-slate_100",
        content: "Mobile is the new Boss.",
        icon: Phone,
    },
    {
        id: "4",
        style: "bg-slate_100",
        content: "",
    },
    {
        id: "5",
        style: "bg-slate_100",
        content: "War must stop",
        icon: Tank,
    },
    {
        id: "6",
        style: "bg-slate_100",
        content: "Design should help people progress.",
        icon: Pen,
    },
    {
        id: "7",
        style: "bg-slate_100",
        content: "Internet is the future.",
        icon: Earth,
    },
];
